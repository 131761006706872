
import { defineComponent } from '@vue/composition-api';
import MainContent from '@/components/MainContent.vue';
import PaginationTable from '@/components/PaginationTable.vue';
import AddDialog from './components/AddDialog.vue';
import { promotionAPi } from '@/api/promotion';
import { ElForm } from 'element-ui/types/form';
import { FormData } from './types';
export default defineComponent({
  components: {
    MainContent,
    PaginationTable,
    AddDialog,
  },
  data() {
    return {
      search: {
        promoteName: '',
        promoteSid: '',
      },
      tableData: [],
      isShowDialogShow: false,
      isAdd: false, //是否新增
      source: {} as FormData, //编辑当前id
    };
  },
  created() {
    (this as any).handleSearch();
  },
  methods: {
    findPage: promotionAPi.findPages,
    handleShowDialog(flag: boolean, val?: any) {
      (this as any).isAdd = flag;
      if ((this as any).isAdd === true) {
        (this as any).source = {} as FormData;
      } else {
        (this as any).source = {
          id: val.id,
          promoteName: val.promoteName,
          promoteSid: val.promoteSid,
        };
      }
      (this as any).isShowDialogShow = true;
    },
    handleSearch() {
      (this as any).$nextTick(() => {
        ((this as any).$refs['pageTable'] as any).onLoad();
      });
    },
    clear(formName: 'searchForm') {
      ((this as any).$refs[formName] as ElForm).resetFields();
    },
    handleDialogClose(type: 'cancel' | 'confirm') {
      if (type === 'confirm') {
        (this as any).handleSearch();
      }
      (this as any).isShowDialogShow = false;
    },
  },
});
