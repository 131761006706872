
import { defineComponent } from '@vue/composition-api';
import { promotionAPi } from '@/api/promotion';
import { FormData } from '../types';
export default defineComponent({
  props: {
    isAdd: {
      type: Boolean,
    },
    source: {
      type: Object as () => FormData,
    },
    isShowDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        promoteName: '',
        promoteSid: '',
      } as FormData,
      visible: true,
      rule: {
        promoteName: [
          { required: true, message: '请输入推广位名称', trigger: 'blur' },
        ],
        promoteSid: [
          {
            required: true,
            message: '请输入推广位SID',
            trigger: ['blur'],
          },
          {
            validator: (this as any).promoteSidValidator,
          },
        ],
      },
    };
  },
  created() {
    (this as any).visible = (this as any).isShowDialogShow;
    (this as any).source?.id
      ? ((this as any).formData = (this as any).source)
      : '';
  },
  methods: {
    handleClose() {
      (this as any).visible = false;
      (this as any).$emit('close', 'cancel');
    },
    handleConfirm(formName: 'promoteForm') {
      ((this as any).$refs[formName] as any).validate((valid: boolean) => {
        if (valid) {
          const urlApi = (this as any).formData.id
            ? promotionAPi.update
            : promotionAPi.create;
          const msg = (this as any).formData.id ? '修改成功' : '新增成功';
          this.loading = true;
          urlApi((this as any).formData).then(() => {
            this.loading = false;
            (this as any).$message.success(msg);
            (this as any).$emit('close', 'confirm');
            (this as any).visible = false;
          }).finally(()=>{
            this.loading = false;
          });
        }
      });
    },
    promoteSidValidator(rule: any, value: any, callback: (value?: any) => any) {
      const valueReg = /^[a-zA-Z0-9]+$/;
      if (value && !valueReg.test(value)) {
        return callback(new Error('仅支持最长64位的英文或数字输入'));
      }
      callback();
    },
  },
});
